import React from "react";
import "./App.css";
import Link from "./components/Links";

function App() {
  const [isDarkMode, setIsDarkMode] = React.useState(true);
  const localStorageKey = "darkModeEnabled";

  // Handle dark mode click
  const handleDarkModeToggle = () => {
    const enabled = !isDarkMode;
    setIsDarkMode(enabled);
    // Set in localstorage
    localStorage.setItem(localStorageKey, `${enabled}`);
  };

  // Watch for changes in isDarkMode
  React.useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  // Set dark mode to dark if set in localStorage on first render
  React.useEffect(() => {
    const enabled =
      localStorage.getItem(localStorageKey) === "true" ||
      !localStorage.getItem(localStorageKey);
    setIsDarkMode(enabled);
  }, []);

  const yearsExperience = React.useMemo(() => {
    const dateNow = new Date();
    const marioStartDate = new Date();
    marioStartDate.setFullYear(2009);
    return dateNow.getFullYear() - marioStartDate.getFullYear();
  }, []);

  return (
    <section className="bg-white dark:bg-gray-900 w-screen h-screen">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
            Mario Edgar
          </h1>
          <p className="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Software Engineer with over {yearsExperience} years of experience,
            specializing in front-end user interface development while also
            possessing extensive full-stack expertise. I am passionate about
            creating inclusive and intuitive experiences that cater to a diverse
            range of users.
          </p>
          <p className="font-light text-gray-500 md:text-lg lg:text-xl dark:text-gray-400">
            Preferred tech:
          </p>
          <p className="mb-6 font-light md:text-lg lg:text-xl ">
            <Link href="https://nodejs.org/">Node.js</Link>,{" "}
            <Link href="https://www.typescriptlang.org/">TypeScript</Link>,{" "}
            <Link href="https://react.dev/">React</Link>,{" "}
            <Link href="https://nextjs.org/">Next.js</Link>,{" "}
            <Link href="https://storybook.js.org/">Storybook</Link>,{" "}
            <Link href="https://www.chromatic.com/">Chromatic</Link>.
          </p>
          <a
            href="mailto:edgarm97@gmail.com"
            className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            <span className="pr-2">Contact</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
              <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
            </svg>
          </a>
          <button
            onClick={handleDarkModeToggle}
            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            <span className="pr-2">Dark</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6 flex"
            >
              <path d="M12 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM7.5 12a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM18.894 6.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM21.75 12a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H21a.75.75 0 0 1 .75.75ZM17.834 18.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM12 18a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 12 18ZM7.758 17.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM6 12a.75.75 0 0 1-.75.75H3a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 6 12ZM6.697 7.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}

export default App;
