import React from "react";

function Link({
  children,
  href,
  target = "_blank",
}: {
  href: string;
  children: string;
  target?: string;
}) {
  return (
    <a href={href} target={target} className="text-blue-500 hover:underline">
      {children}
    </a>
  );
}

export default Link;
